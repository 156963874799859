
export default {
    data() {
        return {
            state: {
                type(){
                    let subdomain = window.location.hostname.split('.')[0]
                    const defaultType = 'cash';

                    let types = {
                        c: 'cash',
                        n: 'nordwind',
                        a: 'auto',
                        v: 'vtb',
                        b: 'bankiros',
                        t: 'travelata',
                        s: 'southwind'
                    }

                    if (subdomain === 'localhost') {
                        return types[this.typeOfDev()] || defaultType;
                    }

                    for (const typesKey in types) {
                        if(subdomain.includes(types[typesKey])) return types[typesKey];
                    }

                    return defaultType;
                },
                typeOfDev(){
                    let params = new URLSearchParams(window.location.search)
                    let typeOfDev = params.get('dev')
                    return typeOfDev
                },
                isNordwind() {
                    return this.type() === 'nordwind'
                },
                appType(type) {
                    console.log('state', this.type())
                    return type === this.type()
                },

                esiaStatus: {
                    //проверка isEsia по гет-параметру appClientId
                    isEsia: location.href.includes("appClientId"),
                    step: 0,
                    usingStep: false,
                    hasError: {
                        esiaClientData: false
                    },
                    data: {},
                    updateStep(step){
                        console.log('updateStep2222222222222222222222222222222222222222222 ', this)
                        this.step = step
                    },
                    intervalId: null

                },
                // buttonsState: {
                //     registration: false
                // }

            }
        }
    },

}
