<template>
    <div class="mark-info">
        <svg v-if="type === 'exclamation'" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 20C4.477 20 0 15.523 0 10C0 4.478 4.477 0 10 0C15.523 0 20 4.478 20 10C20 15.523 15.523 20 10 20ZM10 5C9.73478 5 9.48043 5.10536 9.29289 5.29289C9.10536 5.48043 9 5.73478 9 6V11C9 11.2652 9.10536 11.5196 9.29289 11.7071C9.48043 11.8946 9.73478 12 10 12C10.2652 12 10.5196 11.8946 10.7071 11.7071C10.8946 11.5196 11 11.2652 11 11V6C11 5.73478 10.8946 5.48043 10.7071 5.29289C10.5196 5.10536 10.2652 5 10 5ZM10 15C10.2652 15 10.5196 14.8946 10.7071 14.7071C10.8946 14.5196 11 14.2652 11 14C11 13.7348 10.8946 13.4804 10.7071 13.2929C10.5196 13.1054 10.2652 13 10 13C9.73478 13 9.48043 13.1054 9.29289 13.2929C9.10536 13.4804 9 13.7348 9 14C9 14.2652 9.10536 14.5196 9.29289 14.7071C9.48043 14.8946 9.73478 15 10 15Z" :fill="getMainColor"/>
        </svg>
        <svg v-if="type === 'question'" width="20" height="20" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 0C4.02991 0 0 4.02991 0 9C0 13.9701 4.02991 18 9 18C13.9701 18 18 13.9701 18 9C18 4.02991 13.9701 0 9 0ZM9 14.2232C8.55603 14.2232 8.19643 13.8636 8.19643 13.4196C8.19643 12.9757 8.55603 12.6161 9 12.6161C9.44397 12.6161 9.80357 12.9757 9.80357 13.4196C9.80357 13.8636 9.44397 14.2232 9 14.2232ZM10.2636 9.81362C10.0817 9.88387 9.92514 10.0073 9.81441 10.1679C9.70368 10.3284 9.6439 10.5186 9.64286 10.7136V11.1696C9.64286 11.258 9.57054 11.3304 9.48214 11.3304H8.51786C8.42946 11.3304 8.35714 11.258 8.35714 11.1696V10.7377C8.35714 10.2737 8.49174 9.81562 8.75692 9.43393C9.01607 9.06027 9.37768 8.775 9.80357 8.61228C10.4866 8.34911 10.9286 7.77656 10.9286 7.15179C10.9286 6.26585 10.0627 5.54464 9 5.54464C7.93728 5.54464 7.07143 6.26585 7.07143 7.15179V7.30446C7.07143 7.39286 6.99911 7.46518 6.91071 7.46518H5.94643C5.85804 7.46518 5.78571 7.39286 5.78571 7.30446V7.15179C5.78571 6.36228 6.13125 5.625 6.75804 5.07656C7.36071 4.54821 8.15625 4.25893 9 4.25893C9.84375 4.25893 10.6393 4.55022 11.242 5.07656C11.8687 5.625 12.2143 6.36228 12.2143 7.15179C12.2143 8.31295 11.4489 9.35759 10.2636 9.81362Z" :fill="getMainColor"/>
        </svg>
    </div>
</template>

<script>
export default {
    name: "MarkInfo",
    props: {
        // type указывает на тип отображаемого знака
        // exclamation - восклицательный знак
        // question - знак вопроса
        type: String,
        size: Number,
        color: String
    },
    data(){
        return {
            colorsByType: {
                cash: '#EB3B66',
                nordwind: '#AB292D',
                vtb: '#1976D2',
                travelata: '#EC933D',
                bankiros: '#95CE5A',
                southwind: '#AB292D',
                auto: '#EB3B66'
            }
        }
    },

    computed: {
        getMainColor(){
            let color = null
            if(this.color){
                color = this.color
            }else {
                color = this.colorsByType[`${this.$root.state.type()}`]
            }
            return color;
        },
        getSize(){
            let size = null
            if(this.size){
                size =`${this.size}px`;
            }else {
                size = '20px'
            }
            console.log('size', size)
            return size;
        }
    }
}
</script>


<style scoped lang="scss">
    .mark-info {
        display: inline-block;

        & > svg {
            width: v-bind(getSize);
            height: v-bind(getSize) !important;
            //width: 50px;
            //height: 50px;
        }
    }
</style>
